import React, { useState, useReducer } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
  faBabyCarriage,
  faChild,
  faCrown,
  faTorah,
  faVenus,
  faMars,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BackToTopButton from '../components/BackToTopButton'
import { encode, fetchWitRetry } from '../utils'
import i18n from '../locales/he.yaml'

function reducer(state, action) {
  if (action.type === 'INIT') return {}
  const newState = { ...state }
  if (!action.value) {
    delete newState[action.key]
  } else newState[action.key] = action.value
  return newState
}

const CakesQuery = graphql`
  query CakesQuery {
    mickeyMinnieWeddingCake: file(
      relativePath: {
        eq: "עוגת-החתונה-של-קארין-עמנואל-מיקי-ומיני-מאוס-עם-המון-עוגות-בצורת-קופסאות-מתנה-והכל-מפוסל-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          blurredOptions: { width: 160 }
          layout: FULL_WIDTH
        )
      }
    }
    cancer: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-סרטן-בתלת-מימד-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    minniemouse: file(
      relativePath: {
        eq: "מיני-מאוס-מפוסלת-בבצק-סוכר-בעבודת-יד-מחזיקה-עוגה-באוויר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    thetinman: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-רובוטית-בזהב-מפוסלת-בעבודת-יד-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    girl: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-של-ילדה-עם-משקפיים-לרופאת-עיניים-לילדים-שחוגגת-יום-הולדת-40.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    google: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מעוצבת-בבצק-סוכר-ב-2-קומות-לחגיגות-20-שנה-לגוגל-ישראל.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    halfCake: file(
      relativePath: {
        eq: "עוגת-יום-הולדת-חצי-מעוצבת-בבצק-סוכר-לגיל-חצי-שנה-עם-דובי-ובלונים-מג׳לטין.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    animalsWithTree: file(
      relativePath: {
        eq: "עוגת-חיות-מעוצבת-בבצק-סוכר-עם-ג׳ירפה-זברה-קוף-ואריה-ליום-הולדת-גיל-שנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    hotAirBalloons: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-ליום-הולדת-גיל-שנתיים-של-כדורים-פורחים-וציפורים.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    careBears: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מרחפת-של-דובוני-איכפת-לי-מפוסלים-בבצק-סוכר-ומגלשת-כדורים-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    mashaAndTheBear: file(
      relativePath: {
        eq: "עוגת-2-קומות-של-מאשה-ודוב-מעוצבת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    unicornGravity: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מרחפת-של-חד-קרן-וקשת-בענן-מפוסלת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tree: file(
      relativePath: {
        eq: "עוגת-בת-מצווה-ב-2-קומות-של-עץ-ונערה-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    hipHopGraffiti: file(
      relativePath: {
        eq: "עוגה-מעוצבת-ליום-הולדת-10-בחדר-בריחה-של-בלונדינית-עם-תלתלים-רקדנית-היפ-הופ-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    balletSlippers: file(
      relativePath: {
        eq: "עוגת-בת-מצווה-ורודה-ב-2-קומות-לבלרינה-עם-מלמלה-ונעלי-בלט-מפוסלת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    skateboard: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-בר-מצווה-של-סקייטבורד-תלת-מימד-מפוסל-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    airJordanShoe: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-נעל-תלת-מימד-ענקית-של-אייר-ג׳ורדן-מפוסלת-בבצק-סוכר-בעבודת-יד-לבר-מצווה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tennis: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-כדור-טניס-בתלת-מימד-לבר-מצווה-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    seahorse: file(
      relativePath: {
        eq: "עוגה-מעוצבת-של-סוסון-ים-המפוסל-בבצק-סוכר-בעבודת-יד-ליום-הולדת-40.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    queenOfHears: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-של-מלכת-הלבבות-וקלפים-ליום-הולדת-לאישה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    cookieMonster: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-חתונה-ב-2-קומות-של-עוגיות-שוקולד-צ׳יפס-וקרם-לבן-עם-פרחים.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    weddingCake7Tiers: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-חתונה-ענקית-ב-7-קומות-לבן-עם-כסוף-מעוצבת-בבצק-סוכר-ודמויות-חתן-וכלה-מפוסלים-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    weddingCakeVintage: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-חתונה-מודרנית-לבנה-ב-3-קומות-מצוירת-בעבודת-יד-עם-מכחול-עפ-קישוטי-נייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    roladin: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-ענק-ב-3-קומות-מעוצבת-בבצק-סוכר-לרולדין-שחגגו-30-שנה-בזהב-שחור-ולבן-עם-נשיקות-ומקרונים.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    migdal: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-בצורת-הלוגו-של-חברת-הביטוח-מגדל.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    pumpkin: file(
      relativePath: {
        eq: "עוגת-דלעת-של-האלווין-עטלפים-וכורי-עכביש-ממרשמלו-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    google10k: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-ב-2-קומות-לאירוע-GTG-של-גוגל-ישראל.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    stork: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-חסידה-בתלת-מימד-עם-שק-מפוסלים-בבצק-סוכר-בעבודת-יד-לאירוע-קליטת-עובדים-חדשים-בחברה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    eurovision: file(
      relativePath: {
        eq: "עוגת-2-קומות-מעוצבת-בבצק-סוכר-לחגיגות-אירווזיון-2019-עם-מיקרופון-תווים-ודגלים.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    snoopy: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הבית-של-סנופי-בצבעי-דגל-הגאווה-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    anwarDoors: file(
      relativePath: {
        eq: "עוגה-בצורת-דלת-בתלת-מימד-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bearInHotAirBalloon: file(
      relativePath: { eq: "עוגה-מעוצבת-בבצק-סוכר-לברית-של-דובי-בכדור-פורח.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bears2Tiers: file(
      relativePath: {
        eq: "עוגת-דובונים-ובלונים-באדום-וזהב-לכריסמס-עם-שלג-מעוצבת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dumbo: file(
      relativePath: {
        eq: "עוגת-דמבו-הפיל-בקרקס-ליום-הולדת-גיל-שנה-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    firemanSam: file(
      relativePath: {
        eq: "עוגה-מעוצבת-של-סמי-הכבאי-מפוסל-בבצק-סוכר-בעבודת-יד-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    minnieMouse2d: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-מעוצבת-בבצק-סוכר-של-מיני-מאוס-בדו-מימד-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tractor2d: file(
      relativePath: {
        eq: "עוגה-ליום-הולדת-גיל-שנתיים-עם-טרקטור-ירוק-בדו-מימד-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bearsOnTheSky: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-דובונים-וכדור-פורח-מעוצבת-בבצק-סוכר-ליום-הולדת-גיל-שנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    flamingoTooth: file(
      relativePath: {
        eq: "עוגת-פלמינגו-מעוצבת-בבצק-סוכר-עם-בלונים-לחגיגות-יציאת-שן-ראשונה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    jungle: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-של-גן-חיות-עם-קוף,-ג׳ירפה,-היפופוטם,-אריה-ופיל-ליום-הולדת-גיל-שנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    babyBoss: file(
      relativePath: {
        eq: "עוגת-בייבי-בוס-מעוצבת-בבצק-סוכר-עם-דמות-בדו-מימד-ליום-הולדת-גיל-שנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    winnieThePooh: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-של-פו-הדוב-ליום-הולדת-גיל-שנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    babyInPool: file(
      relativePath: {
        eq: "עוגת-בריכת-כדורים-מעוצבת-בבצק-סוכר-עם-כדורים-ותינוק-ליום-הולדת-גיל-שנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    minnieMouse8Tiers: file(
      relativePath: {
        eq: "עוגת-7-קומות-מעוצבת-בבצק-סוכר-של-כרכרה-עם-סוסים-ומיני-מאוס-ליום-הולדת-גיל-2.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    thomasTheTrain: file(
      relativePath: {
        eq: "עוגת-מעוצבת-בתלת-מימד-של-תומס-הקטר-ועליה-מיקי-מאוס-ליום-הולדת-גיל-3.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bearsSwing: file(
      relativePath: {
        eq: "עוגת-דובונים-מעוצבת-בבצק-סוכר-ליום-הולדת-גיל-שנה-עם-נדנדה-מתנדנדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    fortniteLlama: file(
      relativePath: {
        eq: "עוגת-פיניאטה-בצורת-הלמה-מפורטנייט-בתלת-מימד-מפוסלת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    kinderSurprise: file(
      relativePath: {
        eq: "עוגת-ביצת-קינדר-בתלת-מימד-מפוסלת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    lolFull: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-של-נינג׳גו-ולול-מפוסלת-בעבודת-יד-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    minnieMouse3Tiers: file(
      relativePath: {
        eq: "עוגת-2-קומות-של-מיני-מאוס-בורוד-מפוסלת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    monster: file(
      relativePath: {
        eq: "עוגת-תלת-מימד-מעוצבת-בבצק-סוכר-בצורת-מפלצת-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    ratatouille: file(
      relativePath: {
        eq: "עוגת-רטטוי-מעוצבת-בבצק-סוכר-עם-רמי-העכבר-השף-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    robocar: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-רובוקאר-בתלת-מימד-מפוסלת-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    spiderman: file(
      relativePath: {
        eq: "עוגה-גבוהה-מעוצבת-בבצק-סוכר-של-ספיידרמן-מפוסל-בעבודת-יד-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tractor3d: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגה-המפוסלת-בצורת-טרקטור-תלת-מימד-בבצק-סוכר-לחגיגות-יום-הולדת.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bucket: file(
      relativePath: {
        eq: "עוגת-דלי-עם-צבע-מכחולים-ומברשות-מפוסלת-בבצק-סוכר-בעבודת-יד-לבת-מצווה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    flamingo: file(
      relativePath: {
        eq: "עוגת-פלמינגו-ורודה-מעוצבת-בבצק-סוכר-עם-פרחים-טבעיים-לבת-מצווה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    muslin: file(
      relativePath: {
        eq: "עוגת-בת-מצווה-ורודה-ב-2-קומות-עם-מלמלה-תחרה-ופפיון-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    nickiMinaj: file(
      relativePath: {
        eq: "עוגה-גבוהה-מעוצבת-בבצק-סוכר-של-מוזיקה-וניקי-מינאג׳-מפוסלת-בעבודת-יד-לבת-מצווה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    newYork: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-ניו-יורק-של-בניין-האמפייר-סטייט-מפוסלת-בבצק-סוכר-בעבודת-יד-ליום-הולדת-לאישה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    painted: file(
      relativePath: {
        eq: "עוגת-2-קומות-מעוצבת-בבצק-סוכר-ומצוירת-בעבודת-יד-מכחול-עם-פרחים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    barMitzvah: file(
      relativePath: {
        eq: "עוגת-בר-מצווה-קלאסית-ב-2-קומות-עם-תלית-כיפה-תפילין-וספר-תורה-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    graffiti: file(
      relativePath: {
        eq: "עוגת-גרפיטי-מרובעת-ב-2-קומות-לבר-מצווה-מעוצבת-בבצק-סוכר-וציורים-במכחול-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    jungleBook: file(
      relativePath: {
        eq: "עוגת-בר-מצווה-של-ערימת-ספרים-ספר-תורה-וספר-הג׳ונגל-עם-בלו-הדוב-קא-הנחש-ומוגלי-מפוסלים-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    modernBarMitzvah: file(
      relativePath: {
        eq: "עוגת-בר-מצווה-מודרנית-ב-2-קומות-עם-כיפה-טלית-תפילין-וסידור-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    piramide: file(
      relativePath: {
        eq: "עוגה-בר-מצווה-מעוצבת-בצורת-פירמידה-של-אילומיניטי-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    zeroZeroSeven: file(
      relativePath: {
        eq: "עוגת-בר-מצווה-ב-3-קומות-של-ג׳יממס-בונג-מעוצבת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    alice: file(
      relativePath: {
        eq: "עוגה-שיגיונית-מעוצבת-ליום-הולדת-אליס-בארץ-הפלאות-עם-הזחל-המעשן-קלפים-נרגילה-והחתול-צ׳שייר-החייכן-מפוסלים-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    fashion: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בבצק-סוכר-ליום-הולדת-של-תופרת-סטייליסטית-ומעצבת-אופנה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    khaleesiDragon: file(
      relativePath: {
        eq: "עוגת-דרקון-וביצים-של-חאליסי-ממשחקי-הכס-מפוסלים-בבצק-סוכר-בעבודת-יד-ליום-הולדת-לאישה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    louisVuitton: file(
      relativePath: {
        eq: "עוגה-מעוצבת-של-תיק-לואי-ויטון-עם-פרחים-מפוסלים-בבצק-סוכר-ליום-הולדת-40.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    goofy: file(
      relativePath: {
        eq: "עוגת-גופי-עומד-על-הראש-בתלת-מימד-מפוסל-בבצק-סוכר-בעבודת-יד-ליום-הולדת-לאישה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    whiteRabbit: file(
      relativePath: {
        eq: "עוגת-הארנב-הממהר-מפוסל-בבצק-סוכר-בעבודת-יד-בתלת-מימד-עם-שעון-ענקי-ליום-הולדת-לאישה.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    mercedes: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-מרצדס-בתלת-מימד-מפוסלת-בבצק-סוכר-בעבודת-יד-ליום-הולדת-לגבר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    ferrariRocher: file(
      relativePath: {
        eq: "עוגת-תלת-מימד-מעוצבת-של-פררו-רושה-עם-הסוס-של-פרארי-מפוסלים-בבצק-סוכר-בעבודת-יד-ליום-הולדת-לגבר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    hookah: file(
      relativePath: {
        eq: "עוגה-מעוצבת-של-נרגילה-בזהב-מבצק-סוכר-ליום-הולדת-לגבר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    jeep: file(
      relativePath: {
        eq: "עוגה-מרובעת-מעוצבת-של-ג׳יפ-שטח-אדום-מםוסל-בבצק-סוכר-בעבודת-יד-ליום-הולדת-לגבר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    mail: file(
      relativePath: {
        eq: "עוגת-תיבת-דואר-בתלת-מימד-למחלק-עיתונים-מפוסלת-בבצק-סוכר-בעבודת-יד-ליום-הולדת-לגבר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    gameOfThronesChair: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-כיסא-הברזל-ממשחקי-הכס-מוסל-בבצק-סוכר-בתלת-מימד-בעבודת-יד-ליום-הולדת-לגבר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    movies: file(
      relativePath: {
        eq: "עוגת-חתונה-מעוצבת-של-הסרטים-המסיכה-המטריקס-יורו-טריפ-ולאון-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    classicWeddingCake: file(
      relativePath: {
        eq: "עוגת-חתונה-בצבע-תכלת-ב-3-קומות-עם-פרחים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    goldenCake: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-חתונה-מעוצבת-ב-3-קומות-בזהב-לבן-ושחור-מפוסלת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    superHeroesComics: file(
      relativePath: {
        eq: "עוגת-חתונה-מעוצבת-ב-3-קומות-של-קומיקס-עם-גיבורי-על-הענק-הירוק-וונדר-וומן-סופרמן-באטמן-ספיידרמן-ודדפול-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    vintageCake: file(
      relativePath: {
        eq: "עוגת-חתונה-כפרית-ב-4-קומות-מרחפת-עם-זוג-ינשופים-מפוסלים-בבצק-סוכר-ופרחים-ופרפרים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    whiteWedding: file(
      relativePath: {
        eq: "עוגת-חתונה-לבנה-ב-4-קומות-עם-פרחים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    svetlana: file(
      relativePath: { eq: "קארין-עמנואל-מפסלת-פנים-בעבודת-יד-בבצק-שוקולד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    liranatias: file(relativePath: { eq: "לירן-אטיאס.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, layout: FIXED)
      }
    }
    aditayar: file(relativePath: { eq: "עדי-טייר.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, layout: FIXED)
      }
    }
    lilytopaz: file(relativePath: { eq: "לילי-טופז.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, layout: FIXED)
      }
    }
    bengueta: file(relativePath: { eq: "בן-גואטה.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, layout: FIXED)
      }
    }
  }
`

const CakesTemplate = ({ pathname, images }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [registrationData, dispatchRegistrationData] = useReducer(reducer, {})
  const {
    mickeyMinnieWeddingCake,
    cancer,
    minniemouse,
    thetinman,
    girl,
    google,
    halfCake,
    animalsWithTree,
    hotAirBalloons,
    careBears,
    mashaAndTheBear,
    unicornGravity,
    tree,
    hipHopGraffiti,
    balletSlippers,
    skateboard,
    airJordanShoe,
    tennis,
    seahorse,
    queenOfHears,
    cookieMonster,
    weddingCake7Tiers,
    weddingCakeVintage,
    roladin,
    migdal,
    pumpkin,
    google10k,
    stork,
    eurovision,
    snoopy,
    anwarDoors,
    bearInHotAirBalloon,
    bears2Tiers,
    dumbo,
    firemanSam,
    minnieMouse2d,
    tractor2d,
    bearsOnTheSky,
    flamingoTooth,
    jungle,
    babyBoss,
    winnieThePooh,
    babyInPool,
    minnieMouse8Tiers,
    thomasTheTrain,
    bearsSwing,
    fortniteLlama,
    kinderSurprise,
    lolFull,
    minnieMouse3Tiers,
    monster,
    ratatouille,
    robocar,
    spiderman,
    tractor3d,
    bucket,
    flamingo,
    muslin,
    nickiMinaj,
    newYork,
    painted,
    barMitzvah,
    graffiti,
    jungleBook,
    modernBarMitzvah,
    piramide,
    zeroZeroSeven,
    alice,
    fashion,
    khaleesiDragon,
    louisVuitton,
    goofy,
    whiteRabbit,
    mercedes,
    ferrariRocher,
    hookah,
    jeep,
    mail,
    gameOfThronesChair,
    movies,
    classicWeddingCake,
    goldenCake,
    superHeroesComics,
    vintageCake,
    whiteWedding,
    svetlana,
    liranatias,
    aditayar,
    lilytopaz,
    bengueta
  } = images
  const FEATURE_IMAGE = [cancer, minniemouse, thetinman]
  const CAKES_ICONS = [
    faBabyCarriage,
    faChild,
    faCrown,
    faTorah,
    faVenus,
    faMars,
    faHeart,
    faUsers
  ]
  const BABIES_IMAGE = [
    bearsOnTheSky,
    bearsSwing,
    winnieThePooh,
    babyBoss,
    flamingoTooth,
    babyInPool,
    bearInHotAirBalloon,
    minnieMouse8Tiers,
    jungle,
    halfCake,
    hotAirBalloons,
    animalsWithTree,
    dumbo,
    bears2Tiers,
    thomasTheTrain
  ]
  const LITTLE_ONES_IMAGE = [
    tractor3d,
    ratatouille,
    fortniteLlama,
    kinderSurprise,
    robocar,
    monster,
    lolFull,
    tractor2d,
    unicornGravity,
    careBears,
    mashaAndTheBear,
    minnieMouse3Tiers,
    spiderman,
    minnieMouse2d,
    firemanSam
  ]
  const BAT_MITZVAH_IMAGE = [
    nickiMinaj,
    newYork,
    painted,
    flamingo,
    bucket,
    muslin,
    tree,
    hipHopGraffiti,
    balletSlippers
  ]
  const BAR_MITZVAH_IMAGE = [
    modernBarMitzvah,
    skateboard,
    graffiti,
    jungleBook,
    piramide,
    tennis,
    airJordanShoe,
    barMitzvah,
    zeroZeroSeven
  ]
  const FOR_HER_IMAGE = [
    alice,
    fashion,
    goofy,
    queenOfHears,
    whiteRabbit,
    seahorse,
    louisVuitton,
    girl,
    khaleesiDragon
  ]
  const FOR_HIM_IMAGE = [
    mercedes,
    ferrariRocher,
    jeep,
    mail,
    hookah,
    gameOfThronesChair
  ]
  const WEDDING_IMAGE = [
    goldenCake,
    weddingCakeVintage,
    superHeroesComics,
    weddingCake7Tiers,
    vintageCake,
    movies,
    cookieMonster,
    classicWeddingCake,
    whiteWedding
  ]
  const COMPANY_IMAGE = [
    roladin,
    snoopy,
    migdal,
    eurovision,
    google10k,
    stork,
    pumpkin,
    google,
    anwarDoors
  ]
  const CAKES_IMAGES = [
    BABIES_IMAGE,
    LITTLE_ONES_IMAGE,
    BAT_MITZVAH_IMAGE,
    BAR_MITZVAH_IMAGE,
    FOR_HER_IMAGE,
    FOR_HIM_IMAGE,
    WEDDING_IMAGE,
    COMPANY_IMAGE
  ]
  return (
    <>
      <BackToTopButton selectorId='features' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body has-text-centered content'>
          <div className='container is-margin-top-7'>
            <h1 className='has-text-weight-normal'>{i18n.cakes.title}</h1>
            <h2 className='is-size-5 has-text-weight-normal is-margin-bottom-6'>
              {i18n.cakes.subtitle}
            </h2>
            <a
              href={`${pathname}#registration`}
              className='button is-nude is-medium'
            >
              {i18n.cakes.callToAction}
            </a>
          </div>
        </div>
      </div>
      <div className='swap-on-mobile'>
        <div className='hero'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns'>
                {i18n.cakes.benefits.map((benefit, index) => (
                  <div key={index} className='column has-text-centered'>
                    <FontAwesomeIcon icon={faHeart} className='has-text-nude' />
                    <div className='content'>
                      <h3 className='is-margin-top-4'>{benefit.title}</h3>
                      <p>{benefit.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <GatsbyImage
            image={getImage(mickeyMinnieWeddingCake)}
            loading='eager'
            title={i18n.cakes.mainImage.title}
            alt={i18n.cakes.mainImage.alt}
          />
          <div className='is-flex justify-center content'>
            <p className='has-text-centered has-text-gold-black'>
              {i18n.cakes.mainImage.title}
            </p>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='is-flex justify-center is-margin-bottom-4'>
              <figure className='image is-48x48'>
                <GatsbyImage
                  image={getImage(liranatias)}
                  className='is-rounded'
                  title={i18n.cakes.benefitsQuote.name}
                  alt=''
                />
              </figure>
            </div>
            <div className='is-flex justify-center'>
              <p className='has-text-centered is-size-5 has-text-gold-black'>
                {i18n.cakes.benefitsQuote.text}
              </p>
            </div>
            <p className='has-text-centered is-size-6 has-text-weight-bold is-margin-top-4'>
              {i18n.cakes.benefitsQuote.name}
            </p>
          </div>
        </div>
      </div>
      <div id='features' className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container is-gapless-mobile content'>
            <div className='has-text-centered is-margin-bottom-4'>
              <h3>{i18n.cakes.features.title}</h3>
            </div>
            <div className='has-text-centered is-margin-bottom-7'>
              <p>{i18n.cakes.features.subtitle}</p>
            </div>
            <div className='columns is-mobile is-multiline is-variable is-1-mobile'>
              {FEATURE_IMAGE.map((image, index) => (
                <div
                  key={index}
                  className='column is-one-third has-text-centered'
                >
                  <GatsbyImage
                    image={getImage(image)}
                    title={i18n.cakes.features.images[index].title}
                    alt={i18n.cakes.features.images[index].alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(image).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center'>
                    <p className='has-text-centered has-text-gold-black'>
                      {i18n.cakes.features.images[index].title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <a
              href={`${pathname}#registration`}
              className='button is-nude is-medium'
            >
              {i18n.cakes.callToAction}
            </a>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container is-flex justify-center'>
            <p className='has-text-centered is-size-5 has-text-gold-black'>
              {i18n.cakes.benefit}
            </p>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-light'>
        <div className='hero-body'>
          <div className='container has-text-centered content'>
            <div className='is-margin-bottom-4'>
              <h3>{i18n.cakes.cakesTitle}</h3>
            </div>
            <div className='has-text-centered has-text-grey'>
              <p>{i18n.cakes.cakesDescription}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered'>
              <h4 className='is-margin-bottom-0'>{i18n.cakes.title}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className='has-background-white links-to-sections-container'>
        <div className='columns is-gapless is-multiline'>
          <div className='column is-full is-half-desktop'>
            <div className='tabs is-fullwidth'>
              <ul>
                {i18n.cakes.cakes.slice(0, 4).map((cake, index) => (
                  <li key={cake.short}>
                    <a
                      href={`${pathname}#${cake.short}`}
                      className='is-size-7-mobile'
                    >
                      <FontAwesomeIcon
                        icon={CAKES_ICONS[index]}
                        className='has-text-grey is-margin-right-3'
                        size='xs'
                      />
                      <span className='has-text-grey'>{cake.short}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='column is-full is-half-desktop'>
            <div className='tabs is-fullwidth'>
              <ul>
                {i18n.cakes.cakes.slice(4, 8).map((cake, index) => (
                  <li key={cake.short}>
                    <a
                      href={`${pathname}#${cake.short}`}
                      className='is-size-7-mobile'
                    >
                      <FontAwesomeIcon
                        icon={CAKES_ICONS[index + 4]}
                        className='has-text-grey is-margin-right-3'
                        size='xs'
                      />
                      <span className='has-text-grey'>{cake.short}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {i18n.cakes.cakes.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <div
            id={section.short}
            className={clsx('hero', {
              'has-background-nude-lighter':
                [0, 2, 4, 6].indexOf(sectionIndex) === -1
            })}
          >
            <div key={sectionIndex} className='hero-body'>
              <div className='container has-text-centered is-gapless-mobile content'>
                <div className='is-margin-bottom-4'>
                  <h3>{section.title}</h3>
                </div>
                <div className='is-flex justify-center is-margin-bottom-7'>
                  <p className='has-text-centered'>{section.description}</p>
                </div>
                <div className='columns is-mobile is-multiline is-variable is-1-mobile'>
                  {section.cakes.map((cake, index) => (
                    <div
                      key={index}
                      className='column is-one-third has-text-centered'
                    >
                      <GatsbyImage
                        image={getImage(CAKES_IMAGES[sectionIndex][index])}
                        title={cake.title}
                        alt={cake.alt}
                        className='live-image'
                        style={{
                          maxWidth: getImage(CAKES_IMAGES[sectionIndex][index])
                            .width,
                          margin: '0 auto' // used to center the image
                        }}
                      />
                      <div className='is-flex justify-center'>
                        <p className='has-text-centered has-text-gold-black'>
                          {cake.title}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx('hero', {
              'has-background-nude-lighter':
                [0, 2, 4, 6].indexOf(sectionIndex) === -1
            })}
          >
            <div className='hero-body'>
              <div className='container has-text-centered'>
                <a
                  href={`${pathname}#registration`}
                  className='button is-nude is-medium'
                  onClick={() =>
                    dispatchRegistrationData({
                      key: 'cake',
                      value: section.title
                    })
                  }
                >
                  {i18n.cakes.callToAction}
                </a>
              </div>
            </div>
          </div>
          {sectionIndex === 1 && (
            <div className='hero has-background-nude-lighter'>
              <div className='hero-body'>
                <div className='container'>
                  <div className='is-flex justify-center is-margin-bottom-4'>
                    <figure className='image is-48x48'>
                      <GatsbyImage
                        image={getImage(aditayar)}
                        className='is-rounded'
                        title={i18n.cakes.cakesQuote1.name}
                        alt=''
                      />
                    </figure>
                  </div>
                  <div className='is-flex justify-center'>
                    <p className='has-text-centered is-size-5 has-text-gold-black'>
                      {i18n.cakes.cakesQuote1.text}
                    </p>
                  </div>
                  <p className='has-text-centered is-size-6 has-text-weight-bold is-margin-top-4'>
                    {i18n.cakes.cakesQuote1.name}
                  </p>
                </div>
              </div>
            </div>
          )}
          {sectionIndex === 4 && (
            <div className='hero'>
              <div className='hero-body'>
                <div className='container'>
                  <div className='is-flex justify-center is-margin-bottom-4'>
                    <figure className='image is-48x48'>
                      <GatsbyImage
                        image={getImage(lilytopaz)}
                        className='is-rounded'
                        title={i18n.cakes.cakesQuote2.name}
                        alt=''
                      />
                    </figure>
                  </div>
                  <div className='is-flex justify-center'>
                    <p className='has-text-centered is-size-5 has-text-gold-black'>
                      {i18n.cakes.cakesQuote2.text}
                    </p>
                  </div>
                  <p className='has-text-centered is-size-6 has-text-weight-bold is-margin-top-4'>
                    {i18n.cakes.cakesQuote2.name}
                  </p>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered is-vcentered'>
              <div className='column content is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{i18n.cakes.about.title}</h3>
                <p>
                  {i18n.cakes.about.description.map((t) =>
                    t.text ? (
                      <React.Fragment key={t.text}>{t.text}</React.Fragment>
                    ) : (
                      <span key={t.number} className='is-family-secondary'>
                        &nbsp;{t.number}&nbsp;
                      </span>
                    )
                  )}
                </p>
              </div>
              <div className='column is-10-mobile is-8-tablet is-6-desktop is-5-widescreen is-offset-1-widescreen'>
                <GatsbyImage
                  image={getImage(svetlana)}
                  title={i18n.cakes.about.image.title}
                  alt={i18n.cakes.about.image.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(svetlana).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.cakes.about.image.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-6'>
              <h3>{i18n.cakes.plan.title}</h3>
            </div>
            <div className='is-flex justify-center'>
              <div>
                {i18n.cakes.plan.bullets.map((bullet) => (
                  <p key={bullet}>
                    <FontAwesomeIcon
                      icon={faHeart}
                      className='has-text-nude is-size-7'
                    />
                    <span className='is-margin-left-4'>{bullet}</span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container has-text-centered'>
            <a
              href={`${pathname}#registration`}
              className='button is-nude is-medium'
            >
              {i18n.cakes.callToAction}
            </a>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='is-flex justify-center is-margin-bottom-4'>
              <figure className='image is-48x48'>
                <GatsbyImage
                  image={getImage(bengueta)}
                  className='is-rounded'
                  title={i18n.cakes.planQuote.name}
                  alt=''
                />
              </figure>
            </div>
            <div className='is-flex justify-center'>
              <p className='has-text-centered is-size-5 has-text-gold-black'>
                {i18n.cakes.planQuote.text}
              </p>
            </div>
            <p className='has-text-centered is-size-6 has-text-weight-bold is-margin-top-4'>
              {i18n.cakes.planQuote.name}
            </p>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-light'>
        <div className='hero-body'>
          <div className='container has-text-centered content'>
            <h3 className='is-margin-bottom-6'>
              {i18n.cakes.investment.title}
            </h3>
            {i18n.cakes.investment.description.map((description, index) => (
              <p key={index} className='has-text-grey'>
                {description.map((o, index) =>
                  o.regular ? (
                    <span key={index}>{o.regular} </span>
                  ) : (
                    <strong key={index}>{o.bold} </strong>
                  )
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div id='registration' className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-centered'>
              <div className='column is-narrow'>
                <div className='card'>
                  <header className='card-header direction-column items-center'>
                    <h3 className='card-header-title is-centered is-size-4'>
                      {i18n.cakes.registration.title}
                    </h3>
                    <p className='is-padding-left-5 is-padding-right-5 is-padding-bottom-4'>
                      <span className='is-block'>
                        {i18n.cakes.registration.subtitle}
                      </span>
                      <span className='is-block has-text-weight-bold'>
                        {i18n.cakes.registration.subtitleHint}
                      </span>
                    </p>
                  </header>
                  <div className='card-content'>
                    <form
                      name='cakes'
                      method='POST'
                      action='/cakes/thanks/'
                      data-netlify='true'
                      data-netlify-honeypot='bot-field'
                      onSubmit={async (e) => {
                        e.preventDefault()
                        setIsLoading(true)
                        const form = e.target
                        try {
                          await fetchWitRetry('/', {
                            method: 'POST',
                            headers: {
                              'Content-Type':
                                'application/x-www-form-urlencoded'
                            },
                            body: encode({
                              'form-name': form.getAttribute('name'),
                              ...registrationData
                            })
                          })
                          setIsLoading(false)
                          dispatchRegistrationData({
                            type: 'INIT'
                          })
                          // for some reason without setTimeout the first time it triggers without opening the modal
                          await new Promise((resolve) => {
                            setTimeout(resolve, 0)
                          })
                          navigate(form.getAttribute('action'), {
                            state: { modal: true }
                          })
                        } catch {
                          void 0
                        }
                      }}
                    >
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type='hidden' name='form-name' value='cakes' />
                      <div className='field' hidden>
                        <input
                          name='bot-field'
                          onChange={(e) =>
                            dispatchRegistrationData({
                              key: 'bot-field',
                              value: e.target.value
                            })
                          }
                        />
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor='input-cake'>
                          {i18n.cakes.registration.cake.title}
                        </label>
                        <div className='control'>
                          <div className='select is-fullwidth'>
                            <select
                              id='input-cake'
                              name='cake'
                              value={registrationData.cake || ''}
                              onChange={(e) => {
                                dispatchRegistrationData({
                                  key: 'cake',
                                  value: e.target.value
                                })
                                e.target.setCustomValidity('')
                              }}
                              required
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  i18n.cakes.registration.errors.cake
                                )
                              }
                            >
                              <option value='' disabled />
                              {i18n.cakes.cakes.map((cake) => (
                                <option key={cake.title} value={cake.title}>
                                  {cake.title}
                                </option>
                              ))}
                              <option
                                value={i18n.cakes.registration.cake.other}
                              >
                                {i18n.cakes.registration.cake.other}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor='input-date'>
                          {i18n.cakes.registration.date.title}
                        </label>
                        <div className='control'>
                          <input
                            id='input-date'
                            className='input'
                            type='date'
                            name='date'
                            value={registrationData.date || ''}
                            min={new Date().toISOString().split('T')[0]}
                            placeholder={
                              i18n.cakes.registration.date.placeholder
                            }
                            onChange={(e) => {
                              dispatchRegistrationData({
                                key: 'date',
                                value: e.target.value
                              })
                              e.target.setCustomValidity('')
                            }}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                i18n.cakes.registration.errors.date
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor='input-name'>
                          {i18n.cakes.registration.name.title}
                        </label>
                        <div className='control'>
                          <input
                            id='input-name'
                            className='input'
                            type='text'
                            name='name'
                            value={registrationData.name || ''}
                            placeholder={
                              i18n.cakes.registration.name.placeholder
                            }
                            onChange={(e) => {
                              dispatchRegistrationData({
                                key: 'name',
                                value: e.target.value
                              })
                              e.target.setCustomValidity('')
                            }}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                i18n.cakes.registration.errors.name
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label' htmlFor='input-phone'>
                          {i18n.cakes.registration.phone.title}
                        </label>
                        <div className='control'>
                          <input
                            id='input-phone'
                            className='input is-family-secondary'
                            type='tel'
                            pattern='[0-9]{9-10}'
                            name='phone'
                            value={registrationData.phone || ''}
                            placeholder={
                              i18n.cakes.registration.phone.placeholder
                            }
                            onChange={(e) => {
                              dispatchRegistrationData({
                                key: 'phone',
                                value: e.target.value
                              })
                              e.target.setCustomValidity('')
                            }}
                            required
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                i18n.cakes.registration.errors.phone
                              )
                            }
                          />
                        </div>
                        <p className='help'>
                          {i18n.cakes.registration.phone.hint}
                        </p>
                      </div>
                      <button
                        className={clsx(
                          'button is-nude is-fullwidth is-medium',
                          {
                            'is-loading': isLoading
                          }
                        )}
                        type='submit'
                        disabled={isLoading}
                      >
                        {i18n.cakes.registration.submit}
                      </button>
                      <p className='is-padding-top-5'>
                        <span className='is-block'>
                          {i18n.cakes.registration.footer}
                        </span>
                        <small>
                          <em>{i18n.cakes.registration.footerHint}</em>
                        </small>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Cakes = ({ location: { pathname } }) => {
  const data = useStaticQuery(CakesQuery)
  return (
    <Layout>
      <Seo
        metadata={i18n.cakes.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.mickeyMinnieWeddingCake),
          alt: i18n.cakes.mainImage.alt
        }}
      />
      <CakesTemplate pathname={pathname} images={data} />
    </Layout>
  )
}

export default Cakes
